import React, { useState } from "react";
import "./CourseDescription.css";
import machine from '../../../assets/courses/machine.png';
import Nav from "../../utils/nav/Nav";
import profile from '../../../assets/placeholderimage.jpg';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
  {
    id: 1,
    image: profile,
    name: "Saheb Singh Chaddha",
    occupation: "Big Data Expert & Data Scientist"
  }
]

const MachineLearningDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/3b1b49a8-1f9a-4d61-a01d-1595c9b0c30e/MP4/1-Introduction-to-AI-1.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={machine} alt="kubernetes-thumbnail" />
						) : (
							<img src={machine} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Machine Learning</p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Learn Machine Learning and Data Science, then get hired.
            This comprehensive and project based course will introduce you to all of the modern skills of a Data Scientist and along the way, we will build many real world projects to add to your portfolio.
            <br /><br />
            Data Scientist has been ranked the number one job on Glassdoor and the average salary of a data scientist is over $120,000 in the United States according to Indeed! Data Science is a rewarding career that allows you to solve some of the world's most interesting problems.
          </p>

          <p>Content</p>

          <ol>
            <li>Introduction to AI</li>
            <li>Introduction to ML - Supervised and Unsupervised Learning</li>
            <li>ML KNN - Lp Norms</li>
            <li>ML KNN - Euclidean and Manhattan Distance</li>
            <li>ML KNN - Minkowski, Hamming and Cosine Distance</li>
            <li>ML Over and Under Fitting - Cross Validation and K-Fold CV</li>
            <li>Project 1 Creating the First Model using KNN and finding the Accuracy</li>
            <li>ML Linear Regression </li>
            <li>Project 2 based on SIMPLE LINEAR REGRESSION</li>
            <li>Project 3 based on MULTIPLE LINEAR REGRESSION</li>
            <li>ML HYPOTHESIS TESTING Statistics Fundamentals</li>
            <li>ML Decision Tree with Gini Index</li>
            <li>ML Decision Tree with Information Gain</li>
            <li>Project 4 CASE STUDY based on DECISION TREE</li>
          </ol>
        </div>
        <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img width={200} src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default MachineLearningDesc;
