import React, { useState } from "react";
import "./CourseDescription.css";
import python from '../../../assets/courses/python.png';
import Nav from "../../utils/nav/Nav";
import profile from '../../../assets/placeholderimage.jpg';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
  {
    id: 1,
    image: profile,
    name: "Saheb Singh Chadha",
    occupation: "Data Scientist & Big Data Expert"
  }
]
const PythonForDataScienceDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
	'https://mediadrive.cloudplexo.com/assets/42db674b-6e31-480d-8532-abc46b844133/MP4/1-Introduction-to-Python-in-Data-Science.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={python} alt="kubernetes-thumbnail" />
						) : (
							<img src={python} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Python For Data Science</p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Are you ready to start your path to becoming a Data Scientist!
            This comprehensive course will be your guide to learning how to use the power of Python to analyze data.
            Data Scientist has been ranked the number one job on Glassdoor and the average salary of a data scientist is over $120,000 in the United States according to Indeed!
            Data Science is a rewarding career that allows you to solve some of the world's most interesting problems!
            This course is designed for beginners with no or some programming experience.
          </p>

          <p>Content</p>

          <ol>
            <li>
              Introduction to Python in Data Science
            </li>
            <li>
              Arithmetic Functions
            </li>
            <li>
              Defining Storing Variables and Datatypes
            </li>
            <li>
              Working with Data Types
            </li>
            <li>
              PRACTICE
            </li>
            <li>
              Introduction to Lists
            </li>
            <li>
              SLICING
            </li>
            <li>
              Accessing List Values
            </li>
            <li>
              Sub setting Lists
            </li>
            <li>
              Advanced List Operations
            </li>
            <li>
              Built in Functions
            </li>
            <li>
              Built in Functions part 2
            </li>
            <li>
              Function Arguments
            </li>
            <li>
              Function Arguments Part 2
            </li>
            <li>
              Introduction to String Methods
            </li>
            <li>
              Introduction to String Methods Part 2
            </li>
            <li>
              Importing Python Packages
            </li>
            <li>
              Introduction to String Methods Part 3
            </li>
            <li>
              Subsetting and Comparing Arrays
            </li>
            <li>
              Introduction to NumPy Arrays
            </li>
            <li>
              Subsetting and Comparing Arrays
            </li>
          </ol>
        </div>
        <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img width={200} src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default PythonForDataScienceDesc;
