export const FAQ = [
  {
    id: 1,
    question:
      'Are my card details secured and can I use any card to subscribe?',
    answer: `Yes, your card details are secured. ilearncloud uses Flutterwave and Stripe, a
        secured payment platform. You can also make payment with any credit or debit card.`,
  },
  {
    id: 2,
    question: `Can I learn Coding?`,
    answer: `Yes, you can learn coding on ilearncloud. You can learn Java Programming and Python
        For Data Science.`,
  },
  {
    id: 3,
    question: `Does the Coding Challenge consume internet data?`,
    answer: `No it doesn't. The coding challenge does not consume much internet data. You can use your current internet data plan and ensure that you have sufficient internet data.`,
  },
  {
    id: 4,
    question: `Is it only Cloud Computing courses that are on iLearn Cloud?`,
    answer: `No it's not only cloud computing courses that are on ilearncloud. There are other courses like Microsoft Sharepoint, Machine Learning, Big Data and more.`,
  },
];
