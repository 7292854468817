import React, { useState } from 'react';
import './CourseDescription.css';
import testing from '../../../assets/courses/testing.png';
import Nav from '../../utils/nav/Nav';
import { BsFillPlayFill } from 'react-icons/bs';
import ReactPlayer from 'react-player';

const AutomatedTestingDesc = () => {
	const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
		'https://mediadrive.cloudplexo.com/assets/1add863b-441b-4c2d-b386-2fb37c74b01e/MP4/1-Introduction.mp4';

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
	};

	return (
		<React.Fragment>
			<Nav />
			<div className="course-description">
				<div className="course-description-intro">
					<div className="course-thumbnail">
						{!playVid ? (
							<img src={testing} alt="kubernetes-thumbnail" />
						) : (
							<img src={testing} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
					</div>

					<div>
						<div>
							<p className="course-title">Automated Software Testing Using Selenium</p>
						</div>
						<div className="go-to-course-btn">
							<a href="https://app.ilearncloud.io/login">
								<button>Go to Course</button>
							</a>
						</div>
					</div>
				</div>

				<div className="blue-background" />

				<div className="course-description-content">
					<h5>Course Description</h5>
					<hr />

					<p>
						Selenium is a web testing tool which uses simple scripts to run tests directly within a browser.
						In simple terms, “it automates browsers”.. Do you want to master the automated process of
						testing using Selenium?
						<br /> <br />
						We are here to guide you on the journey to mastery.
					</p>

					<p>Content</p>

					<ol>
						<li>Module 1. Introduction of the course</li>
						<li>Module 2. Core Java Essentials for Selenium</li>
						<li>Module 3. Selenium IDE</li>
						<li>Module 4. Selenium RC Remote Control 1_0 API Deprecated</li>
						<li>Module 5. Xpath VS CSS Locators</li>
						<li>Module 6. Selenium Webdriver 2_0 Basics</li>
						<li>Module 7. Selenium Webdriver 2_0 Advance</li>
					</ol>
				</div>
				{/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
			</div>
		</React.Fragment>
	);
};

export default AutomatedTestingDesc;
