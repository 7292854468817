import React from 'react';
import Nav from '../../utils/nav/Nav';
import Footer from '../../utils/footer/Footer';
import LanderFAQ from '../../utils/faq-lander/faq';

const FAQ = () => {
  return (
    <>
      <Nav />
      <LanderFAQ />
      <Footer />
    </>
  );
};

export default FAQ;
