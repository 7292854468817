import React from "react";
import Nav from "../../utils/nav/Nav";
import { Link } from "react-router-dom";
import classes from "./Business.module.css";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const FormSubmitted = () => {
  return (
    <>
      <Nav />

      <div className={classes["form-submitted"]}>
        <IoIosCheckmarkCircleOutline id={classes["font"]} />
        <h1>Thank you!</h1>
        <p>Form submitted and we would get back to you soon</p>

        <Link to="/business" className={classes["form-submitted-button"]}>
          Back to Business page
        </Link>
      </div>
    </>
  );
};

export default FormSubmitted;
