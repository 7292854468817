import React, { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Nav.module.css';
import logo from '../../../assets/ILC Icon.png';

class Nav extends Component {
  mobileDropdownRef = createRef();

  state = {
    mobileDropdown: false,
  };

  drop = () => {
    this.setState({
      mobileDropdown: !this.state.mobileDropdown,
    });
  };

  // active class on desktop screen
  activeNavLinkStyle = ({ isActive }) => {
    return {
      color: isActive ? '#516beb' : '#667085',
      textDecoration: isActive ? 'underline' : ' ',
    };
  };

  // active class on mobile screen
  activeMobileNavLinkStyle = ({ isActive }) => {
    return {
      textDecoration: isActive ? 'underline' : ' ',
    };
  };

  render() {
    const { mobileDropdown } = this.state;
    return (
      <nav>
        {/* below is the hamburger toggle menu bar on small screens */}
        <div className={classes['mobile-navbar']}>
          <div className={classes['mobile-nav-body']}>
            <section>
              <img
                src={logo}
                alt="logo"
              />
              <h3>iLearn Cloud</h3>
            </section>

            <section>
              <a
                href="https://app.ilearncloud.io/login"
                className={classes['mobile-nav-button']}
              >
                Log In
              </a>

              <div
                className={
                  classes['mobile-hamburger-btn'] +
                  ' ' +
                  (mobileDropdown ? classes['close'] : classes[''])
                }
                onClick={this.drop}
              />
            </section>
          </div>

          <ul
            ref={this.mobileDropdownRef}
            className={
              classes['mobile-nav-links'] +
              ' ' +
              (mobileDropdown ? classes['open'] : classes[''])
            }
          >
            <NavLink
              to="/"
              className={classes['mobile-nav-list']}
              style={this.activeMobileNavLinkStyle}
            >
              Home
            </NavLink>
            <NavLink
              to="/business"
              className={classes['mobile-nav-list']}
              style={this.activeMobileNavLinkStyle}
            >
              Business
            </NavLink>
            <NavLink
              to="/pricing"
              className={classes['mobile-nav-list']}
              style={this.activeMobileNavLinkStyle}
            >
              Pricing
            </NavLink>

            <NavLink
              to="/faq"
              className={classes['mobile-nav-list']}
              style={this.activeMobileNavLinkStyle}
            >
              FAQ
            </NavLink>

            <NavLink
              to="/contact"
              className={classes['mobile-nav-list']}
              style={this.activeMobileNavLinkStyle}
            >
              Contact Us
            </NavLink>
            <a
              href=" https://app.ilearncloud.io/signup"
              className={classes['mobile-nav-list']}
            >
              Register
            </a>
          </ul>
        </div>

        {/* below is the navbar on desktop screens */}
        <section className={classes['desktop-nav-parent']}>
          <div className={classes['desktop-navbar']}>
            <div className={classes['icon-logo']}>
              <img
                src={logo}
                alt="logo"
                className={classes['desktop-navbar-img']}
              />
              <h3>iLearn Cloud</h3>
            </div>

            <div className={classes['desktop-nav-links']}>
              <NavLink
                to="/"
                className={classes['desktop-nav-list']}
                style={this.activeNavLinkStyle}
              >
                Home
              </NavLink>
              <NavLink
                to="/business"
                className={classes['desktop-nav-list']}
                style={this.activeNavLinkStyle}
              >
                Business
              </NavLink>
              <NavLink
                to="/pricing"
                className={classes['desktop-nav-list']}
                style={this.activeNavLinkStyle}
              >
                Pricing
              </NavLink>

              <a
                href=" https://app.ilearncloud.io/signup"
                className={classes['desktop-nav-list']}
              >
                Register
              </a>

              <NavLink
                to="/faq"
                className={classes['desktop-nav-list']}
                style={this.activeNavLinkStyle}
              >
                FAQ
              </NavLink>

              <NavLink
                to="/contact"
                className={classes['desktop-nav-list']}
                style={this.activeNavLinkStyle}
              >
                Contact Us
              </NavLink>

              <a
                href="https://app.ilearncloud.io/login"
                className={classes['login-link']}
              >
                Log In
              </a>
            </div>
          </div>
        </section>
      </nav>
    );
  }
}

export default Nav;
