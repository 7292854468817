import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import classes from './Pagination.module.css';
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
} from 'react-icons/ai';
import { MdOutlineArrowOutward } from 'react-icons/md';
import { IoIosSearch } from 'react-icons/io';
import { BsFillPlayFill } from 'react-icons/bs';

const Pagination = ({ data, pageLimit, dataLimit, loadMore }) => {
  const [videoIndex, setVideoIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResult, setSearchResult] = useState('');
  const [pages] = useState(Math.round(data.length / dataLimit));

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;

    // if there's a search, then return an array of searched items
    if (searchResult !== '') {
      return data
        .slice(startIndex, endIndex)
        .filter((course) =>
          course.name.toLowerCase().includes(searchResult.toLowerCase())
        );
    } else {
      return data.slice(startIndex, endIndex);
    }
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  //play video on click of image
  const handlePlayImgVideo = (e) => {
    setVideoIndex(e);
  };

  // function to handle course searches
  const handleCourseSearch = (e) => {
    setSearchResult(e.target.value);
  };

  return (
    <section className={classes['courses-container']}>
      <div className={classes['courses-container-header']}>
        <h2>Our Highly-Requested Courses</h2>

        <div className={classes['search-bar']}>
          <input
            type="text"
            placeholder="Find a course..."
            name="searchResult"
            value={searchResult}
            onChange={handleCourseSearch}
          />
          <IoIosSearch id={classes['font']} />
        </div>
      </div>

      <div className={classes['pagination']}>
        {/* previous button */}
        <p
          onClick={goToPreviousPage}
          className={
            classes['prev'] +
            ' ' +
            (currentPage === 1 ? classes['disabled'] : classes[' '])
          }
        >
          <AiOutlineArrowLeft
            className={
              classes['courses-category-font'] +
              ' ' +
              (currentPage === 1 ? classes['disabled'] : classes[' '])
            }
          />
          Previous
        </p>

        {/* show page numbers */}
        <section>
          {getPaginationGroup().map((item, index) => (
            <p
              key={index}
              onClick={changePage}
              className={
                classes['paginationItem'] +
                ' ' +
                (currentPage === item ? classes['active'] : classes[' '])
              }
            >
              <span>{item}</span>
            </p>
          ))}
        </section>

        {/* next button */}
        <p
          onClick={goToNextPage}
          className={
            classes['next'] +
            ' ' +
            (currentPage === pages + 1 ? classes['disabled'] : classes[' '])
          }
        >
          Next
          <AiOutlineArrowRight
            className={
              classes['courses-category-font-right'] +
              ' ' +
              (currentPage === pages + 1 ? classes['disabled'] : classes[' '])
            }
          />
        </p>
      </div>

      <div className={classes['flex-courses']}>
        {getPaginatedData().map((post) => (
          <article
            className={classes['flex-courses-column']}
            key={post.id}
          >
            <div className={classes['flex-courses-image-container']}>
              {post.id === videoIndex ? (
                <img
                  src={post.img}
                  alt="img thumbnail"
                  style={{ display: 'none' }}
                />
              ) : (
                <React.Fragment>
                  <img
                    src={post.img}
                    alt="img thumbnail"
                  />
                  <div
                    className={classes['play-icon-body']}
                    onClick={() => handlePlayImgVideo(post.id)}
                  >
                    <BsFillPlayFill className={classes['play-icon']} />
                  </div>
                </React.Fragment>
              )}
              {post.id === videoIndex ? (
                <ReactPlayer
                  controls={true}
                  className={classes['react-player']}
                  url={post.vid}
                  width="100%"
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                        onContextMenu: (e) => e.preventDefault(),
                      },
                    },
                  }}
                />
              ) : null}
            </div>

            <div className={classes['hyperlink-flex']}>
              <a href={post.link}>{post.name}</a>
              <MdOutlineArrowOutward className={classes['hyperlink-icon']} />
            </div>
          </article>
        ))}

        {currentPage === 3 || currentPage == 2 ? null : (
          <button onClick={loadMore}>
            {dataLimit === 21 ? (
              <AiOutlineArrowUp className={classes['button-icon']} />
            ) : (
              <AiOutlineArrowDown className={classes['button-icon']} />
            )}
            {dataLimit === 21 ? 'See less' : 'Load More'}
          </button>
        )}
      </div>
    </section>
  );
};

export default Pagination;
