import React, { useEffect } from 'react';
import './Pricing.css';
import Nav from '../../utils/nav/Nav';
import { pricingPage } from '../../utils/facebookEvent';
import check from '../../../assets/pricing/Check icon.png';
import cancel from '../../../assets/pricing/cancel icon.png';
import library from '../../../assets/pricing/library icon.png';
import practice from '../../../assets/pricing/practice icon.png';
import certificate from '../../../assets/pricing/certificate icon.png';
import LanderFAQ from '../../utils/faq-lander/faq';
import Footer from '../../utils/footer/Footer';
import pricingIcon from '../../../assets/pricing/pricingIcon.png';

const featuresArray = [
  {
    img: library,
    title: 'Expanding Library',
    paragraph:
      'Our library consist of most-popular topics such as cloud, software develepoment, data, Artificial Intelligence, Machine Learning and more.',
  },
  {
    img: practice,
    title: 'Expanding Learn with Practice',
    paragraph:
      'Learning with practice are currently expanding to cover top programming languages such as javascript, python, C++, C#, Go and more.',
  },
  {
    img: cancel,
    title: 'Cancel Anytime',
    paragraph:
      'Subscription can be cancelled at anytime, no contracts, no obligations. However, we hope you enjoy ilearncloud and never want to leave.',
  },
  {
    img: certificate,
    title: 'Present your Certificate',
    paragraph:
      'Let the world recognize and celebrate your achievements with our prestigious certification program.',
  },
];

const Pricing = () => {
  useEffect(() => {
    pricingPage();
  }, []);

  return (
    <>
      <Nav />

      <section className="pricing-page">
        <h4>Pricing plans</h4>
        <h1>Plans for all sizes</h1>
        <p>
          Simple, transparent pricing that grows with you. Try any plan free for
          30 days.
        </p>

        <div className="popularHeading">
          <img
            src={pricingIcon}
            alt="ilearn Cloud"
          />
          <h2>Most popular!</h2>
        </div>

        <div className="pricing-card">
          {/* Basic Plan */}
          <div>
            <h2>Free (start for Free)</h2>
            <h3>Basic plan</h3>
            <p>
              Get practical training in DevOps, Cloud, Software Development,
              Data Science and more.{' '}
            </p>

            <ul>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Full Access to our content library
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Career coach on a free consultation how to achieve your goal
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Access to coding challenges with other study peers
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Participation in innovation challenge
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Certificate of completion
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Earn money while you learn by joining our affiliate program
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Project based learning (coming soon)
              </li>
            </ul>
            <a href=" https://app.ilearncloud.io/signup">Get Started</a>
          </div>

          {/* Business Plan */}
          <div>
            <h3>Business plan</h3>
            <p>The essentials for an easy team onboarding </p>

            <ul>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Unlimited Access to top-courses
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Upskill 5 or 10,000 with most comprehensive learning library.
              </li>
              <li>
                <img
                  src={check}
                  alt="IlearnCloud"
                />
                Highly quality content by experienced instructors and
                professionals
              </li>
            </ul>
            <a href="/contact">Contact Us</a>
          </div>
        </div>
      </section>

      <section className="features">
        <section>
          <h4>Features</h4>
          <h2>
            Discover a world of knowledge and opportunities right at your
            fingertips.
          </h2>
          <p>
            iLearn Cloud is your gateway to limitless learning, empowering you
            to gain new skills, explore fresh horizons, and shape your future
          </p>
        </section>

        <section className="different-features">
          {featuresArray.map((feature) => (
            <div key={feature.title}>
              <img
                src={feature.img}
                alt="iLearnCloud pricing"
              />
              <h3>{feature.title}</h3>
              <p>{feature.paragraph}</p>
            </div>
          ))}
        </section>
      </section>

      <LanderFAQ />

      <Footer />
    </>
  );
};
export default Pricing;
