import React, { useState } from 'react';
import './CourseDescription.css';
import bigdataspark from '../../../assets/courses/bigdata-spark.png';
import Nav from '../../utils/nav/Nav';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const BigDataWithSparkDesc = () => {
	const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
		'https://mediadrive.cloudplexo.com/assets/4f7caa7c-eb92-4682-b9f8-f2ed21b4aee9/MP4/1-C1-Scala-1.mp4';

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
	};

	return (
		<React.Fragment>
			<Nav />
			<div className="course-description">
				<div className="course-description-intro">
					<div className="course-thumbnail">
						{!playVid ? (
							<img src={bigdataspark} alt="kubernetes-thumbnail" />
						) : (
							<img src={bigdataspark} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
					</div>

					<div>
						<div>
							<p className="course-title">Big Data With Spark</p>
						</div>
						<div className="go-to-course-btn">
							<a href="https://app.ilearncloud.io/login">
								<button>Go to Course</button>
							</a>
						</div>
					</div>
				</div>

				<div className="blue-background" />

				<div className="course-description-content">
					<h5>Course Description</h5>
					<hr />

					<p>
						Spark and Scala work together to analyze big data. Spark, however is unique in providing batch
						as well as streaming capabilities, thus making it a preferred choice for lightening fast Big
						Data Analysis platforms. Scala and Spark are two of the most in demand skills right now, and
						with this course you can learn them quickly and easily! This course comes packed with content.
					</p>

					<p>Content</p>

					<ol>
						<li>Introduction to Scala</li>
						<li>Spark Practicals</li>
						<li>Spark SQL</li>
						<li>Spark SQL Practicals</li>
						<li>Kafka</li>
						<li>Kafka Practicals</li>
						<li>Spark Streaming</li>
						<li>Spark Streaming Practicals</li>
					</ol>
				</div>
				{/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
			</div>
		</React.Fragment>
	);
};

export default BigDataWithSparkDesc;
