import React from 'react';
import Marquee from 'react-fast-marquee';
import classes from './client.module.css';
import client4 from '../../../assets/partners/logo.png';
import client2 from '../../../assets/partners/BRlogo.png';
import client5 from '../../../assets/partners/checkpoint.png';
import client1 from '../../../assets/partners/Accenture 1.png';
import client3 from '../../../assets/partners/GTCO_logo.png';
import client7 from '../../../assets/partners/MaxFiles-logo.png';
import client6 from '../../../assets/partners/Microsoft_logo.png';
import client8 from '../../../assets/partners/Google_Cloud_logo.png';

const Clients = () => {
  const partnerLogos = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client7,
    client6,
    client8,
  ];

  return (
    <section className={classes['partners-and-clients']}>
      <h2>Trusted Collaborators & Valued Clientele</h2>

      <Marquee
        direction="right"
        speed={100}
        delay={5}
        autoFill={true}
      >
        {partnerLogos.map((logo) => (
          <React.Fragment key={logo}>
            <img
              src={logo}
              alt="Client and partner Logos"
            />
          </React.Fragment>
        ))}
      </Marquee>
    </section>
  );
};

export default Clients;
