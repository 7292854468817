import React, { useLayoutEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

import Homepage from './components/pages/homepage/Homepage';
import Pricing from './components/pages/pricing/Pricing';
import Contact from './components/pages/contact/Contact';
import Nav from './components/utils/nav/Nav';
import Business from './components/pages/business/Business';
import FAQ from './components/pages/faq/FAQ';
// Courses Description Pages
import AwsCertifiedCloudPractitionerDesc from './components/pages/coursedescription/AwsCertifiedCloudPractitionerDesc';
import DataScienceAndMLWithRDesc from './components/pages/coursedescription/DataScienceAndMLWithRDesc';
import DatabasePostgreSQLDesc from './components/pages/coursedescription/DatabasePostgreSQLDesc';
import DevopsDesc from './components/pages/coursedescription/DevopsDesc';
import EdgeComputingDesc from './components/pages/coursedescription/EdgeComputingDesc';
import BigDataBeginnerHadoopDesc from './components/pages/coursedescription/BigDataBeginnerHadoopDesc';
import BigDataWithSparkDesc from './components/pages/coursedescription/BigDataWithSparkDesc';
import CIJenkinsDesc from './components/pages/coursedescription/CIJenkinsDesc';
import CoreJavaEssentialsDesc from './components/pages/coursedescription/CoreJavaEssentialsDesc';
import LeveragingCloudDesc from './components/pages/coursedescription/LeveragingCloudDesc';
import MachineLearningDesc from './components/pages/coursedescription/MachineLearningDesc';
import NetworkingCCNADesc from './components/pages/coursedescription/NetworkingCCNADesc';
import NoSQLAndMongoDBDesc from './components/pages/coursedescription/NoSQLAndMongoDBDesc';
import PythonForDataScienceDesc from './components/pages/coursedescription/PythonForDataScienceDesc';
import VersionControlDesc from './components/pages/coursedescription/VersionControlDesc';
import UnixShellScriptingDesc from './components/pages/coursedescription/UnixShellScriptingDesc';
import KubernetesDesc from './components/pages/coursedescription/KubernetesDesc';
import AutomatedTestingDesc from './components/pages/coursedescription/AutomatedTestingDesc';
import BigDataAdvancedHadoopDesc from './components/pages/coursedescription/BigDataAdvancedHadoopDesc';
import MicrosoftAzureDesc from './components/pages/coursedescription/MicrosoftDesc';
import MicrosoftSharepoint from './components/pages/coursedescription/MicrosoftSharepoint';
import FormSubmitted from './components/pages/business/FormSubmitted';
import AWSArchitectDesc from './components/pages/coursedescription/AWSArchitectDesc';
import AIPoweredAWSComprehendDesc from './components/pages/coursedescription/AIPoweredAWSComprehendDesc';

function Error() {
  return (
    <div>
      <Nav />

      <section
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px',
        }}
      >
        <h2 style={{ fontSize: '44px', marginBottom: 0, fontWeight: 'bold' }}>
          404 ERROR
        </h2>
        <p style={{ fontSize: '16px' }}>Page not found</p>
      </section>
    </div>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />

      <Routes>
        <Route
          exact
          path="/"
          element={<Homepage />}
        />
        <Route
          exact
          path="/pricing"
          element={<Pricing />}
        />
        <Route
          exact
          path="/business"
          element={<Business />}
        />
        <Route
          exact
          path="/contact"
          element={<Contact />}
        />
        <Route
          exact
          path="/faq"
          element={<FAQ />}
        />
        <Route
          exact
          path="/business/form-successful"
          element={<FormSubmitted />}
        />
        {/* Course Description Pages */}
        <Route
          exact
          path="/aws-cloud-practitioner"
          element={<AwsCertifiedCloudPractitionerDesc />}
        />
        <Route
          exact
          path="/data-science-and-ml"
          element={<DataScienceAndMLWithRDesc />}
        />
        <Route
          exact
          path="/database-and-postgresql"
          element={<DatabasePostgreSQLDesc />}
        />
        <Route
          exact
          path="/devops-with-ansible"
          element={<DevopsDesc />}
        />
        <Route
          exact
          path="/edge-computing"
          element={<EdgeComputingDesc />}
        />
        <Route
          exact
          path="/machine-learning"
          element={<MachineLearningDesc />}
        />
        <Route
          exact
          path="/big-data-advanced-hadoop-ecosystem"
          element={<BigDataAdvancedHadoopDesc />}
        />
        <Route
          exact
          path="/big-data-beginner-hadoop-ecosystem"
          element={<BigDataBeginnerHadoopDesc />}
        />
        <Route
          exact
          path="/big-data-with-spark"
          element={<BigDataWithSparkDesc />}
        />
        <Route
          exact
          path="/intro-to-continous-integration-ci-jenkins"
          element={<CIJenkinsDesc />}
        />
        <Route
          exact
          path="/java-programming"
          element={<CoreJavaEssentialsDesc />}
        />
        <Route
          exact
          path="/leveraging-cloud"
          element={<LeveragingCloudDesc />}
        />
        <Route
          exact
          path="/networking-ccna"
          element={<NetworkingCCNADesc />}
        />
        <Route
          exact
          path="/non-relational-database"
          element={<NoSQLAndMongoDBDesc />}
        />
        <Route
          exact
          path="/python-for-data-science"
          element={<PythonForDataScienceDesc />}
        />
        <Route
          exact
          path="/git-and-github"
          element={<VersionControlDesc />}
        />
        <Route
          exact
          path="/unix-shell-scripting"
          element={<UnixShellScriptingDesc />}
        />
        <Route
          exact
          path="/kubernetes"
          element={<KubernetesDesc />}
        />
        <Route
          exact
          path="/automated-software-testing"
          element={<AutomatedTestingDesc />}
        />
        <Route
          exact
          path="/microsoft-azure-fundamentals"
          element={<MicrosoftAzureDesc />}
        />
        <Route
          exact
          path="/microsoft-sharepoint"
          element={<MicrosoftSharepoint />}
        />
        <Route
          exact
          path="/aws-certified-solutions-architect-associate-desc"
          element={<AWSArchitectDesc />}
        />
        <Route
          exact
          path="/ai-powered-natural-language-processing-with-aws-comprehend-desc"
          element={<AIPoweredAWSComprehendDesc />}
        />
        <Route
          path="*"
          element={<Error />}
        />
      </Routes>
    </Router>
  );
}

export default App;
