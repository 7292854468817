import React, { useState } from 'react';
import "./CourseDescription.css";
import database from '../../../assets/courses/database.png';
import Nav from "../../utils/nav/Nav";
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const DatabasePostgreSQLDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/4de4903f-618a-4fcb-bdf5-9307d81d9758/MP4/1-Introduction-to-PostgreSQL-1.mp4'
  
	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">

        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={database} alt="kubernetes-thumbnail" />
						) : (
							<img src={database} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Relational Databases And PostgreSQL </p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Every app in the world needs to store information in a database.
            Although there are many different databases you can use,
            PostgreSQL has been a number-one pick for decades, offering scalable performance,
            rock-solid uptime, and flexible design systems.
            This course will teach you everything you need to know about PostgreSQL to use it on your next big project!
          </p>

          <p>Content</p>

          <ol>
            <li>
              Introduction
            </li>
            <li>
              Retrieve data using select statement
            </li>
            <li>
              Data filter in select
            </li>
            <li>
              Data exploration using select
            </li>
            <li>
              Joins
            </li>
            <li>
              Set operators
            </li>
            <li>
              Aggregate function
            </li>
            <li>
              Group Data
            </li>
            <li>
              Build compress quieries
            </li>
            <li>
              String and number builtin functions
            </li>
            <li>
              Date and time built in functions
            </li>
            <li>
              Datatype conversion builtin functions
            </li>
            <li>
              Sub-query
            </li>
            <li>
              DML Operations
            </li>
          </ol>
        </div>
        {/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
      </div>
    </>
  );
};

export default DatabasePostgreSQLDesc;
