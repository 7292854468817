import React, { useState } from "react";
import "./CourseDescription.css";
import cloud from '../../../assets/courses/cloud.png';
import Nav from "../../utils/nav/Nav";
import ewere from '../../../assets/Ewere.png';
import mayowa from '../../../assets/Mayowa.png';
import ben from '../../../assets/Benjamin.png';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
  {
    id: 1,
    image: ewere,
    name: "Ewere Diagboya",
    occupation: "Cloud Engineer"
  },
  {
    id: 2,
    image: ben,
    name: "Benjamin Oyemonlan",
    occupation: "CTO, Patricia Technologies"
  },
  {
    id: 3,
    image: mayowa,
    name: "Mayowa Anibaba",
    occupation: "Head of Engineering, Renmoney"
  }
]
const LeveragingCloudDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/57a900c8-b8b7-4883-b0bb-5a40937d48d9/MP4/1-Cloud-adoption-and-business-usecases-Patricia.mp4'
  
	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={cloud} alt="kubernetes-thumbnail" />
						) : (
							<img src={cloud} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">
                Leveraging Cloud Solutions For Business Growth
              </p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            This course is a webinar delivered by FinTech companies leveraging
            cloud for business growth. Learn how Cloud Computing is leveraged to
            boost innovation, agility and application development, as well as
            reducing infrastructure costs by 20-30% from CEOs and CTOs discussing
            actual industry use-cases. Leveraging cloud security and resilience
            aspects can also reduce costs of downtime and breaches.
          </p>

          <p>Content</p>

          <ol>
            <li>
              Cloud Adoption and Real-Time Cases( CTO - Patricia Technologies)
            </li>
            <li>
              Cloud Adoption and Real-Time Cases(Head of Engineering - RenMoney)
            </li>
            <li>
              Cloud Adoption and Cloud Financial Management (AWS Community Hero
              And Head of Infrastructure and Data Protection Indicina)
            </li>
          </ol>
        </div>
        <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default LeveragingCloudDesc;
