import React, { useState } from 'react';
import './CourseDescription.css';
import Nav from '../../utils/nav/Nav';
import Footer from '../../utils/footer/Footer';
import { BsFillPlayFill } from 'react-icons/bs';
import CourseThumbnailAwsCertified from '../../../assets/courses/AI-Powered Natural Language Processing with AWS Comprehend.webp';

const AIPoweredAWSComprehendDesc = () => {
  const [playVid, setPlayVid] = useState(false);
  const videoLink =
    'https://mediadrive.cloudplexo.com/assets/242c7314-c0e7-4175-a30f-96ed93138bff/MP4/1-Intro-Comprehend-Overview-Section-1-2.mp4';

  //play video on click of image
  const handlePlayImgVideo = () => {
    setPlayVid(true);
  };

  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
            {!playVid ? (
              <img
                src={CourseThumbnailAwsCertified}
                alt="kubernetes-thumbnail"
              />
            ) : (
              <img
                src={CourseThumbnailAwsCertified}
                alt="kubernetes-thumbnail"
                style={{ display: 'none' }}
              />
            )}
            <div
              className="play-icon-body"
              onClick={handlePlayImgVideo}
            >
              <BsFillPlayFill className="play-icon" />
            </div>
            {playVid ? (
              <ReactPlayer
                controls={true}
                className="react-player"
                url={videoLink}
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                      onContextMenu: (e) => e.preventDefault(),
                    },
                  },
                }}
              />
            ) : null}{' '}
          </div>

          <div>
            <div>
              <p className="course-title">
                AI-Powered Natural Language Processing with AWS Comprehend
              </p>
            </div>
            <div className="go-to-course-btn">
              <a href="https://app.ilearncloud.io/login">
                <button>Go to Course</button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Amazon Comprehend is a natural-language processing (NLP) service
            that uses machine learning to uncover valuable insights and
            connections in text. Are you ready to take the next step of your
            career?
            <br /> <br />
            We are here to help you start your journey to AI-Powered Natural
            Language Processing with AWS Comprehend. We focus on helping you
            understand the cloud. Once you understand cloud well, you will be
            able to use your skills on AWS or any cloud platform.
          </p>

          {/* <p>Content</p>
          <ol>
            <li>Module 1. Introduction To Cloud Computing</li>
            <li>Module 2. Amazon Web Services Overview</li>
            <li>Module 3. AWS Global Infrastucture</li>
            <li>Module 4. Compute Services </li>
            <li>Module 5. Storage Services</li>
            <li>Module 6. Networking Services</li>
            <li>Module 7. Database Services</li>
            <li>Module 8. Security and Compliance Services</li>
            <li>Module 9. Cost Management Services</li>
            <li>Module 10. Management and Governance Service</li>
            <li>Module 11. Cloud Deploymen Services</li>
          </ol> */}

          {/* <div className="container">
           <h3>Course Instructor(s)</h3>
        <div className="row instructor-profile">
              {CourseInstructor.length > 0 &&
                CourseInstructor.map((instructor) => (
                  <div className="col-md-4">
                    <div>
                      <img
                        width={200}
                        src={instructor.image}
                        className="img-fluid"
                        alt="instructor"
                      />
                      <h4>{instructor.name}</h4>
                      <p>{instructor.occupation}</p>
                    </div>
                  </div>
                ))}

          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default AIPoweredAWSComprehendDesc;
