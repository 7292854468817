import React, { useState, useCallback } from 'react';
import Nav from '../../utils/nav/Nav';
import Footer from '../../utils/footer/Footer';
import Clients from '../../utils/clients/clients';
import classes from './Business.module.css';
import LanderFAQ from '../../utils/faq-lander/faq';

import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import images
import Hero from '../../../assets/business/hero.png';
import Third from '../../../assets/business/stock-broker.png';
import Second from '../../../assets/business/remote-ideation.png';
import First from '../../../assets/business/video-conferencing.png';

const Business = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // mode: 'onBlur'
  });

  // form handle function
  const onFormSubmit = useCallback((data, e) => {
    setLoading(true);
    const url =
      'https://e9ku5u8hhe.execute-api.eu-west-1.amazonaws.com/Prod/scheduledemo';

    axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          e.target.reset();
          navigate('form-successful');
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          theme: 'colored',
        });
      });
  }, []);

  return (
    <div>
      <Nav />
      <ToastContainer />

      <section className={classes['business-hero-section']}>
        <div>
          <h1>iLearnCloud For Business</h1>

          <p>
            Document, Automate, Track, Report & Deliver Educational Courses on
            our online E-Learning LMS Platform.
          </p>

          <form onSubmit={handleSubmit(onFormSubmit)}>
            <label htmlFor="fname">
              Full Name
              <input
                type="text"
                name="fname"
                placeholder="Input your full name"
                {...register('fname', { required: 'Name is required' })}
              />
              {errors.fname && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 14,
                    textAlign: 'right',
                    marginTop: -20,
                  }}
                >
                  {errors.fname.message}
                </p>
              )}
            </label>

            <label htmlFor="phone">
              Phone Number
              <input
                type="text"
                placeholder="••••••••"
                {...register('phone', {
                  required: 'Enter a phone number',
                  minLength: {
                    value: 10,
                    message: 'Enter a valid phone number',
                  },
                })}
              />
              {errors.phone && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 14,
                    textAlign: 'right',
                    marginTop: -20,
                  }}
                >
                  {errors.phone.message}
                </p>
              )}
            </label>

            <label htmlFor="jobtitle">
              Job Title
              <input
                type="text"
                name="jobtitle"
                placeholder="••••••••"
                {...register('jobtitle', { required: 'Add a Job Title' })}
              />
              {errors.jobtitle && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 14,
                    textAlign: 'right',
                    marginTop: -20,
                  }}
                >
                  {errors.jobtitle.message}
                </p>
              )}
            </label>

            <label htmlFor="email">
              Company Email Address
              <input
                type="text"
                name="email"
                placeholder="••••••••"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'This is not a valid email address',
                  },
                })}
              />
              {errors.email && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 14,
                    textAlign: 'right',
                    marginTop: -20,
                  }}
                >
                  {errors.email.message}
                </p>
              )}
            </label>

            <label htmlFor="companyname">
              Company Name
              <input
                type="text"
                name="companyname"
                placeholder="••••••••"
                {...register('companyname', { required: 'Add a Company name' })}
              />
              {errors.companyname && (
                <p
                  style={{
                    color: 'red',
                    fontSize: 14,
                    textAlign: 'right',
                    marginTop: -20,
                  }}
                >
                  {errors.companyname.message}
                </p>
              )}
            </label>
            <button>{loading ? 'Submitting...' : 'Submit'}</button>
          </form>
        </div>

        <div>
          <img
            src={Hero}
            alt="hero img"
          />
        </div>
      </section>

      <section className={classes['business-offer']}>
        <h2>What We Offer</h2>

        <section>
          <div>
            <img
              src={First}
              alt="img"
            />
            <p>Have a Better oversight on your staff training & development.</p>
          </div>

          <div>
            <img
              src={Second}
              alt="img"
            />
            <p>Staff training made easier </p>
          </div>

          <div>
            <img
              src={Third}
              alt="img"
            />
            <p>Record keeping of staff development progress</p>
          </div>
        </section>
      </section>

      <Clients />

      <LanderFAQ />

      <Footer />
    </div>
  );
};

export default Business;
