import React, { useState } from "react";
import "./CourseDescription.css";
import networking from '../../../assets/courses/networking.png';
import Nav from "../../utils/nav/Nav";
import profile from '../../../assets/placeholderimage.jpg';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
  {
    id: 1,
    image: profile,
    name: "Sikandar Shaik",
    occupation: "Cisco Trainer/Network Consultant"
  },
]

const NetworkingCCNADesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
	'https://mediadrive.cloudplexo.com/assets/2cdfbc0c-89ee-4b1a-b279-745e9d6fd68c/MP4/1-New-CCNAx-200-25-Routing-and-Switching-Overview.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={networking} alt="kubernetes-thumbnail" />
						) : (
							<img src={networking} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Networking CCNA</p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            This course would introduce you to the world of networking.
            The Course would prepare you to achieve the Cisco CCNA certification.
            The course would start with the very basic of networking and thereafter go further deep dive into core networking.
            The course is good for Cisco CCENT and CCNA certification candidates or anyone interested in learning networking.
            In addition, the course is also useful to anyone interested in entering a career in network technologies.


          </p>

          <p>Content</p>

          <ol>
            <li>
              Introduction
            </li>
            <li>
              TCP IP addressing
            </li>
            <li>
              Subnetting FLSM
            </li>
            <li>
              Subnetting VLSM
            </li>
            <li>
              Subnetting Questions
            </li>
            <li>
              Introduction to Cisco-Routers
            </li>
            <li>
              Basic Commands
            </li>
            <li>
              Dynamic Routing RIPv2
            </li>
            <li>
              Dynamic Routing EIGRP
            </li>
            <li>
              Dynamic Routing OSPF
            </li>
            <li>
              Basic Switching
            </li>
            <li>
              VLAN And Trunking
            </li>
            <li>
              VLAN Trunking protocol
            </li>
            <li>
              Spanning Tree Protocol
            </li>
            <li>
              STP optimization
            </li>
            <li>
              Access control List
            </li>
            <li>
              Network Address Translation
            </li>
            <li>
              IPv6 addressing
            </li>
            <li>
              OSI Model and TCPIP protocols
            </li>
            <li>
              TCP IP addressing
            </li>
            <li>
              Troubleshooting Switching
            </li>
            <li>
              Troubleshooting Routing
            </li>
            <li>
              Advance Security
            </li>
            <li>
              IP services
            </li>
          </ol>
        </div>
        <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img width={200} src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default NetworkingCCNADesc;
