import React, { useState } from 'react';
import './CourseDescription.css';
import Nav from '../../utils/nav/Nav';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';
import sharepoint from '../../../assets/courses/microsoft-sharepoint-thumbnail.png';

const MicrosoftAzureDesc = () => {
	const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
		'https://mediadrive.cloudplexo.com/assets/0b71ee2a-bdfe-499a-917b-b71ddde72357/MP4/1-Instructor-introduction-video.mp4';

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
	};

	return (
		<React.Fragment>
			<Nav />
			<div className="course-description">
				<div className="course-description-intro">
					<div className="course-thumbnail">
						{!playVid ? (
							<img src={sharepoint} alt="kubernetes-thumbnail" />
						) : (
							<img src={sharepoint} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
					</div>

					<div>
						<div>
							<p className="course-title">Microsoft Sharepoint</p>
						</div>
						<div className="go-to-course-btn">
							<a href="https://app.ilearncloud.io/login">
								<button>Go to Course</button>
							</a>
						</div>
					</div>
				</div>

				<div className="blue-background" />

				<div className="course-description-content">
					<h5>Course Description</h5>
					<hr />

					<p>
						This course introduces you to the basics of cloud computing and Azure
						<br /> <br />
						We are here to guide you on the journey to mastery.
					</p>

					<p>Content</p>

					<ol>
						<li>Module 1. Introduction of the course</li>
						<li>Module 2. Azure Core Services</li>
						<li>Module 3. Cloud Concepts</li>
						<li>Module 4. Azure Core Solutions</li>
						<li>Module 5. Security in Azure</li>
						<li>Module 6. Identity governance privacy and compliance</li>
						<li>Module 7. Pricing Cost SLAs</li>
					</ol>
				</div>
				{/* <div className="container">
			<div className="row instructor-profile">
			  {CourseInstructor.map((instructor) => (
				<div className="col-md-4">
				  <div>
					<img src={instructor.image} className="img-fluid" alt="instructor" />
					<h4>{instructor.name}</h4>
					<p>{instructor.occupation}</p>
				  </div>
				</div>
			  ))}
  
			</div>
		  </div> */}
			</div>
		</React.Fragment>
	);
};

export default MicrosoftAzureDesc;
