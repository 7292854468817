import React, { useState } from 'react';
import './CourseDescription.css';
import awsImg from '../../../assets/courses/aws.png';
import Nav from '../../utils/nav/Nav';
import profile from '../../../assets/placeholderimage.jpg';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
	{
		id: 1,
		image: profile,
		name: 'Samuel Joseph',
		occupation: 'Cloud Engineer & AWS Solutions Architect'
	}
];
const AwsCertifiedCloudPractitionerDesc = () => {
	const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
		'https://mediadrive.cloudplexo.com/assets/61e862c4-f086-409d-abfe-97e741aec628/MP4/1-Module-1-Inroduction-To-Cloud-Computing.mp4';

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
	};

	return (
		<div>
			<Nav />
			<div className="course-description">
				<div className="course-description-intro">
					<div className="course-thumbnail">
						{!playVid ? (
							<img src={awsImg} alt="kubernetes-thumbnail" />
						) : (
							<img src={awsImg} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
					</div>

					<div>
						<div>
							<p className="course-title">AWS Certified Cloud Practitioner</p>
						</div>
						<div className="go-to-course-btn">
							<a href="https://app.ilearncloud.io/login">
								<button>Go to Course</button>
							</a>
						</div>
					</div>
				</div>

				<div className="blue-background" />

				<div className="course-description-content">
					<h5>Course Description</h5>
					<hr />

					<p>
						AWS Certified Cloud Practitioner is an easy certification to get your journey started with AWS.
						Do you want to become an AWS Certified Cloud Practitioner? Are you ready to take the next step
						of your career? <br /> <br />
						We are here to help you start your journey to AWS Certified Cloud Practitioner. Cloud Computing
						is the future. We focus on helping you understand the cloud. Once you understand cloud well, you
						will be able to use your skills on AWS or any cloud platform.
					</p>

					<p>Content</p>

					<ol>
						<li>Module 1. Inroduction To Cloud Computing</li>
						<li>Module 2. Amazon Web Services Overview</li>
						<li>Module 3. AWS Global Infrastucture</li>
						<li>Module 4. Compute Services </li>
						<li>Module 5. Storage Services</li>
						<li>Module 6. Networking Services</li>
						<li>Module 7. Database Services</li>
						<li>Module 8. Security and Compliance Services</li>
						<li>Module 9. Cost Management Services</li>
						<li>Module 10. Management and Governance Service</li>
						<li>Module 11. Cloud Deploymen Services</li>
					</ol>
				</div>
				<div className="container">
					<div className="row instructor-profile">
						{CourseInstructor.map((instructor) => (
							<div className="col-md-4">
								<div>
									<img width={200} src={instructor.image} className="img-fluid" alt="instructor" />
									<h4>{instructor.name}</h4>
									<p>{instructor.occupation}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AwsCertifiedCloudPractitionerDesc;
