import React, { useState } from 'react';
import classes from './faq.module.css';
import Avatar from '../../../assets/Avatar.png';
import Avatar2 from '../../../assets/Avatar2.png';
import Avatar3 from '../../../assets/Avatar3.png';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { FAQ } from './questions';
import { Link } from 'react-router-dom';

const LanderFAQ = () => {
  const [idx, setIdx] = useState(null);
  const [dropdown, setDropdown] = useState(true);

  const handleOpen = (id) => {
    setIdx(id);
    setDropdown(true);
  };

  return (
    <section className={classes['faq-section']}>
      <h2>Frequently asked questions</h2>
      <p>Everything you need to know about the product and billing.</p>
      {FAQ.map((fa) => (
        <div
          key={fa.id}
          className={classes['faq']}
        >
          <div>
            <h4>{fa.question}</h4>

            {dropdown && idx === fa.id ? (
              <AiOutlineMinusCircle
                id={classes['icon']}
                onClick={() => setDropdown(false)}
              />
            ) : (
              <AiOutlinePlusCircle
                id={classes['icon']}
                onClick={() => handleOpen(fa.id)}
              />
            )}
          </div>

          {dropdown && idx === fa.id && <p>{fa.answer}</p>}
        </div>
      ))}
      <section className={classes['faq-avatars']}>
        <img
          src={Avatar3}
          alt="iLearnCloud"
        />
        <img
          src={Avatar}
          alt="iLearnCloud"
        />
        <img
          src={Avatar2}
          alt="iLearnCloud"
        />
      </section>
      <h4 id={classes['faq-h2']}>Still have questions?</h4>
      <p>
        Can’t find the answer you’re looking for? Please chat to our friendly
        team.
      </p>
      <Link
        to="/contact"
        style={{ textDecoration: 'none' }}
      >
        <button>Get in touch</button>
      </Link>
    </section>
  );
};

export default LanderFAQ;
