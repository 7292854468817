// importing popular courses images
import awsImg from '../../../assets/courses/aws.png';
import bigdata from '../../../assets/courses/bigdata.png';
import computing from '../../../assets/courses/computing.png';
import devops from '../../../assets/courses/devops.png';
import machine from '../../../assets/courses/machine.png';
import nosql from '../../../assets/courses/nosql.png';
import databse from '../../../assets/courses/database.png';
import datascience from '../../../assets/courses/datascience.png';
import networking from '../../../assets/courses/networking.png';
import testing from '../../../assets/courses/testing.png';
import kubernetes from '../../../assets/courses/kubernetes.png';
import python from '../../../assets/courses/python.png';
import github from '../../../assets/courses/github.png';
import java from '../../../assets/courses/java.png';
import integration from '../../../assets/courses/integration.png';
import cloud from '../../../assets/courses/cloud.png';
import unix from '../../../assets/courses/unix.png';
import sharepoint from '../../../assets/courses/microsoft-sharepoint-thumbnail.png';
import microsoftazure from '../../../assets/courses/microsoftazure.png';
import bigdatabeginner from '../../../assets/courses/bigdata-beginner.png';
import bigdataspark from '../../../assets/courses/bigdata-spark.png';
import AWSSolutionsArchitectThumbnail from '../../../assets/courses/AWS Certified Solutions Architect Associate.webp';
import AIPoweredAWSComprehendThumbnail from '../../../assets/courses/AI-Powered Natural Language Processing with AWS Comprehend.webp';

// array of available posts
export const posts = [
  {
    id: 2,
    name: 'Big Data Advanced Hadoop ecosystem',
    img: bigdata,
    link: '/big-data-advanced-hadoop-ecosystem',
    vid: 'https://mediadrive.cloudplexo.com/assets/c71863ff-b372-4390-a953-dc336b8ffe00/MP4/1-B1-NoSQL-1.mp4',
  },
  {
    id: 3,
    name: 'Complete Data Science and Machine Learning with R',
    img: datascience,
    link: '/data-science-and-ml',
    vid: 'https://mediadrive.cloudplexo.com/assets/d615fae6-25db-43e8-a9ac-12ce3668d8c9/MP4/1-Introduction.mp4',
  },

  {
    id: 4,
    name: 'Relational Databases & PostGRESQL',
    img: databse,
    link: '/database-and-postgresql',
    vid: 'https://mediadrive.cloudplexo.com/assets/4de4903f-618a-4fcb-bdf5-9307d81d9758/MP4/1-Introduction-to-PostgreSQL-1.mp4',
  },
  {
    id: 5,
    name: 'Devops with Ansible',
    img: devops,
    link: '/devops-with-ansible',
    vid: 'https://mediadrive.cloudplexo.com/assets/a4eb9925-8a10-41aa-8011-0624327de8fd/MP4/1-Class20-ansible.mp4',
  },
  {
    id: 6,
    name: 'Edge Computing: Driving the next evolution of internet Technology',
    img: computing,
    link: '/edge-computing',
    vid: 'https://mediadrive.cloudplexo.com/assets/1bd46519-7692-414a-b17f-c209d228d7a2/MP4/1-Introduction-to-cloud-and-edge-computing.mp4',
  },
  {
    id: 7,
    name: 'Machine Learning',
    img: machine,
    link: '/machine-learning',
    vid: 'https://mediadrive.cloudplexo.com/assets/3b1b49a8-1f9a-4d61-a01d-1595c9b0c30e/MP4/1-Introduction-to-AI-1.mp4',
  },
  {
    id: 8,
    name: 'Networking CCNA',
    img: networking,
    link: '/networking-ccna',
    vid: 'https://mediadrive.cloudplexo.com/assets/2cdfbc0c-89ee-4b1a-b279-745e9d6fd68c/MP4/1-New-CCNAx-200-25-Routing-and-Switching-Overview.mp4',
  },
  {
    id: 9,
    name: 'Non Relational Databases - NoSQL & MongoDB',
    img: nosql,
    link: '/non-relational-database',
    vid: 'https://mediadrive.cloudplexo.com/assets/193d8f0f-c67e-4e2b-ba4d-df77619c878b/MP4/1-Introduction-to-NoSQL-Databases.mp4',
  },
  {
    id: 10,
    name: 'Automated Software Testing Using Selenium',
    img: testing,
    link: '/automated-software-testing',
    vid: 'https://mediadrive.cloudplexo.com/assets/1add863b-441b-4c2d-b386-2fb37c74b01e/MP4/1-Introduction.mp4',
  },
  {
    id: 12,
    name: 'Python for Data Science',
    img: python,
    link: '/python-for-data-science',
    vid: 'https://mediadrive.cloudplexo.com/assets/42db674b-6e31-480d-8532-abc46b844133/MP4/1-Introduction-to-Python-in-Data-Science.mp4',
  },
  {
    id: 13,
    name: 'Introduction to version control Git/Github',
    img: github,
    link: '/git-and-github',
    vid: 'https://mediadrive.cloudplexo.com/assets/e5a7fce1-afa7-4ea8-857d-ee4263e2aaed/MP4/1-Introduction-to-Version-control-system.mp4',
  },
  {
    id: 14,
    name: 'Java programming',
    img: java,
    link: '/java-programming',
    vid: 'https://mediadrive.cloudplexo.com/assets/03d595a7-611a-4a84-ad94-bd284f6fc524/MP4/1-introduction-to-java.mp4',
  },
  {
    id: 15,
    name: 'Leveraging Cloud for business growth',
    img: cloud,
    link: '/leveraging-cloud',
    vid: 'https://mediadrive.cloudplexo.com/assets/57a900c8-b8b7-4883-b0bb-5a40937d48d9/MP4/1-Cloud-adoption-and-business-usecases-Patricia.mp4',
  },
  {
    id: 16,
    name: 'Introduction to Continous Integration CI-Jenkins',
    img: integration,
    link: '/intro-to-continous-integration-ci-jenkins',
    vid: 'https://mediadrive.cloudplexo.com/assets/6684e3f6-d919-470e-a88f-5189303c9f51/MP4/1-Introduction-to-Continuous-Integration-and-configuring-Jenkins.mp4',
  },
  {
    id: 17,
    name: 'Bigdata Beginner Hadoop ecosystem',
    img: bigdatabeginner,
    link: '/big-data-beginner-hadoop-ecosystem',
    vid: 'https://mediadrive.cloudplexo.com/assets/f21f9d5c-07db-4413-b900-3bbc1eed402d/MP4/1-A1-Hadoop-intro-1.mp4',
  },
  {
    id: 18,
    name: 'Bigdata with Spark',
    img: bigdataspark,
    link: '/big-data-with-spark',
    vid: 'https://mediadrive.cloudplexo.com/assets/4f7caa7c-eb92-4682-b9f8-f2ed21b4aee9/MP4/1-C1-Scala-1.mp4',
  },
  {
    id: 19,
    name: 'Unix Shell Scripting',
    img: unix,
    link: '/unix-shell-scripting',
    vid: 'https://mediadrive.cloudplexo.com/assets/df7b00c3-5cb1-488c-8cf5-6115cfdf3f7b/MP4/1-Shell-Introduction-1.mp4',
  },
  {
    id: 11,
    name: 'Kubernetes',
    img: kubernetes,
    link: '/kubernetes',
    vid: 'https://mediadrive.cloudplexo.com/assets/21052419-8488-439a-bbdd-7773e6aadabd/MP4/1-Background-and-top-features.mp4',
  },
  {
    id: 1,
    name: 'AWS Certified Cloud Practitioner Course',
    img: awsImg,
    link: '/aws-cloud-practitioner',
    vid: 'https://mediadrive.cloudplexo.com/assets/61e862c4-f086-409d-abfe-97e741aec628/MP4/1-Module-1-Inroduction-To-Cloud-Computing.mp4',
  },
  {
    id: 20,
    name: 'Microsoft Azure Fundamentals',
    img: microsoftazure,
    link: '/microsoft-azure-fundamentals',
    vid: 'https://mediadrive.cloudplexo.com/assets/2ed7fc5f-8a19-45fa-ae00-2d8ef794a046/MP4/1-Introductory-Video.mp4',
  },
  {
    id: 21,
    name: 'Microsoft Sharepoint',
    img: sharepoint,
    link: '/microsoft-sharepoint',
    vid: 'https://mediadrive.cloudplexo.com/assets/0b71ee2a-bdfe-499a-917b-b71ddde72357/MP4/1-Instructor-introduction-video.mp4',
  },
  {
    id: 22,
    name: 'AI-Powered Natural Language Processing with AWS Comprehend',
    img: AIPoweredAWSComprehendThumbnail,
    link: '/ai-powered-natural-language-processing-with-aws-comprehend-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/242c7314-c0e7-4175-a30f-96ed93138bff/MP4/1-Intro-Comprehend-Overview-Section-1-2.mp4',
  },
  {
    id: 23,
    name: 'AWS Certified Solutions Architect Associate',
    img: AWSSolutionsArchitectThumbnail,
    link: '/aws-certified-solutions-architect-associate-desc',
    vid: 'https://mediadrive.cloudplexo.com/assets/e24ea0aa-c549-4437-9ede-b3dfb697fa1c/MP4/0-a-Lecture-Introduction-to-AWS.mp4',
  },
];
