import React, { useState } from 'react';
import Nav from '../../utils/nav/Nav';
import Footer from '../../utils/footer/Footer';
import classes from './Contact.module.css';
import ContactImage from '../../../assets/ContactUs.png';

// import react-phone-number
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';

const Contact = () => {
  const [applied, setApplied] = useState('no preferred method');
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm();

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const onSubmit = (data) => {
    const dataForm = {
      fullname: data.firstname + ' ' + data.lastname,
      phone: data.mobilenumber,
      email: data.email,
      preferredcontact: applied,
      marketinginfo: isChecked ? 'subscribed' : 'not subscribed',
      desc: data.message,
    };

    const url =
      'https://wwhc15ac1a.execute-api.eu-west-1.amazonaws.com/Prod/ilearncloudcontactform';
    axios
      .post(url, dataForm)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        alert('Form not submitted');
      });
  };

  return (
    <>
      <Nav />

      <section className={classes['contact-page']}>
        <section className={classes['contact-form']}>
          <h1>Contact us</h1>
          <p>Our friendly team would love to hear from you.</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Name */}
            <div>
              <label htmlFor="firstname">
                First name
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder="First name"
                  {...register('firstname', {
                    required: 'Please enter your name',
                    minLength: {
                      value: 2,
                      message: 'Your name must exceed 1 characters',
                    },
                  })}
                />
                {errors.firstname && (
                  <p className={classes['error']}>{errors.firstname.message}</p>
                )}
              </label>

              <label htmlFor="lastname">
                Last name
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Last name"
                  {...register('lastname', {
                    required: 'Please enter your name',
                    minLength: {
                      value: 2,
                      message: 'Your name must exceed 1 characters',
                    },
                  })}
                />
                {errors.lastname && (
                  <p className={classes['error']}>{errors.lastname.message}</p>
                )}
              </label>
            </div>

            {/* Email */}
            <label htmlFor="email">
              Email
              <input
                type="text"
                id="email"
                name="email"
                placeholder="you@company.com"
                className={classes['input']}
                {...register('email', {
                  required: 'Please enter your email address',
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: 'Please enter a valid email address',
                  },
                })}
              />
              {errors.email && (
                <p className={classes['error']}>{errors.email.message}</p>
              )}
            </label>

            {/* Phone Number */}
            <label htmlFor="mobilenumber">
              Phone number
              <Controller
                id="mobilenumber"
                name="mobilenumber"
                control={control}
                {...register('mobilenumber', {
                  required: 'Enter a phone number',
                  minLength: {
                    value: 10,
                    message: 'Enter a valid phone number',
                  },
                })}
                render={({ field: { ref, ...field } }) => (
                  <PhoneInput
                    {...field}
                    className={classes['mobile-input']}
                    inputStyle={{
                      borderRadius: 8,
                      border: ' 0.89px solid #d0d5dd',
                      fontSize: 16,
                      height: '5.5vh',
                      width: '100%',
                    }}
                    buttonStyle={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    dropdownStyle={{
                      maxHeight: '90vh',
                      maxWidth: '30vw',
                      padding: '0 10px',
                    }}
                    control={control}
                    country={'ng'}
                  />
                )}
              />
              {errors.mobilenumber && (
                <p className={classes['error']}>
                  {errors.mobilenumber.message}
                </p>
              )}
            </label>

            <label>
              Preferred Contact Method
              <section>
                <select
                  value={applied}
                  onChange={(e) => setApplied(e.target.value)}
                >
                  <option value="No preferred contact">
                    No Preferred Contact
                  </option>
                  <option value="Phone">Phone</option>
                  <option value="Email">Email</option>
                </select>
              </section>
            </label>

            <label htmlFor="message">
              Message(optional)
              <textarea
                id="message"
                name="message"
                cols="30"
                rows="10"
                {...register('message')}
              />
            </label>

            <p>
              <input
                type="checkbox"
                name="subscribed"
                onChange={handleOnChange}
                id={classes['checkbox']}
              />
              Sign up to our marketing offers and discounts
            </p>

            <button>Send message</button>
          </form>
        </section>

        <section className={classes['contact-image']}>
          <img
            src={ContactImage}
            alt="ilearn Cloud"
          />
        </section>
      </section>

      <Footer />
    </>
  );
};

export default Contact;
