import React, { useState } from "react";
import "./CourseDescription.css";
import java from '../../../assets/courses/java.png';
import Nav from "../../utils/nav/Nav";
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CoreJavaEssentialsDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/03d595a7-611a-4a84-ad94-bd284f6fc524/MP4/1-introduction-to-java.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={java} alt="kubernetes-thumbnail" />
						) : (
							<img src={java} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Java Programming</p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Java is one of the most popular programming languages.
            Java offers both object-oriented and functional programming features. We take a hands-on approach. This course assumes no previous programming or Java experience.
            If you never programmed a computer before, or if you already have experience with another programming language and want to learn Java, this is a perfect course for you.
            The course for individuals with ZERO programming experience and want to learn Java Programming
            or a Beginner at Java Programming and want to Learn to write Great Java Programs.

          </p>

          <p>Content</p>

          <ol>
            <li>
              Introduction to java and installing Java Windows OS
            </li>
            <li>
              MAC-OS-X Installing Java and configuring enviornment variables
            </li>
            <li>
              Classes and Objects
            </li>
            <li>
              Basic Java programming and features Part 1
            </li>
            <li>
              Basic Java programming and features Part 2
            </li>
            <li>
              Basic Java programming and features Part 2
            </li>
            <li>
              Java Methods
            </li>
            <li>
              Java Methods Part 2
            </li>
            <li>
              Java Methods Part 3
            </li>


            <li>
              Java Methods Part 4
            </li>
            <li>
              Java Methods Part 5 Predefined Methods
            </li>
            <li>
              Java Methods Part 6 Static and Non Static Methods
            </li>
            <li>
              IF and Else Statements
            </li>
            <li>
              Loops While Loop
            </li>
            <li>
              Loops Do While Loop
            </li>
            <li>
              Loops For Loop
            </li>
            <li>
              Loops Nested Loops
            </li>
            <li>
              Loops Break and Continue statements
            </li>
            <li>
              If Else statements and Switch Case
            </li>
            <li>
              Arrays and Methods
            </li>
            <li>
              OOPS Static and Non Static
            </li>
            <li>
              OOPS Part 2
            </li>
            <li>
              OOPS Object oriented programming Part 3
            </li>
            <li>
              Exception Handling Part 1
            </li>
            <li>
              Exception Handling Part 2
            </li>
            <li>
              Packages and Access Modifiers
            </li>
            <li>
              Reading Properties files
            </li>
            <li>
              Java Streams Writing in TXT and CSV files
            </li>
            <li>
              Java Stream Writing HTML Files and Reading files
            </li>
            <li>
              Java Stream Apache POI Creating and Writing Excel Files
            </li>
            <li>
              Classes and Objects
            </li>
            <li>
              Java Stream Apache POI Reading Excel Files Extended Loops
            </li>
            <li>
              Reading Notepad file
            </li>
            <li>
              Reading Excel Files and Generating logs through Log4J API
            </li>
            <li>
              Java Collections
            </li>
            <li>
              Reflection API
            </li>
            <li>
              Difference between equal to operator and Singleton design pattern
            </li>
            <li>
              Apache Log4J API Console and File Appenders
            </li>
            <li>
              Apache Log4J API SMTPAppender
            </li>
            <li>
              Apache Log4J API HTMLAppender
            </li>
          </ol>
        </div>
        {/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
      </div>
    </>
  );
};

export default CoreJavaEssentialsDesc;
