import React, { useState } from 'react';
import "./CourseDescription.css";
import devops from '../../../assets/courses/devops.png';
import Nav from "../../utils/nav/Nav";
import profile from '../../../assets/placeholderimage.jpg';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
  {
    id: 1,
    image: profile,
    name: "Probin",
    occupation: "Devops Engineer & Enthusiast"
  }
]

const DevopsDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/a4eb9925-8a10-41aa-8011-0624327de8fd/MP4/1-Class20-ansible.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={devops} alt="kubernetes-thumbnail" />
						) : (
							<img src={devops} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Devops With Ansible </p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Are you just started working on your DevOps career? Or looking to learn and transition to devops?
            Then this course is for you. Ansible for DevOps course would help you to understand how Ansible works and automate your day to day activities.
            The course would discuss various topics like ad-hoc commands, playbooks, variables, inventory, roles with examples.

          </p>

          <p>Content</p>

          <ol>
            <li>
              Introduction to Ansible
            </li>
            <li>
              How to work with Ansible and AWS
            </li>
            <li>
              Dynamic Inventories,  playbooks, variables
            </li>
            <li>
              Managing and securing information
            </li>
            <li>
              Speed up your Ansible run times
            </li>
          </ol>
        </div>
        <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img width={200} src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default DevopsDesc;
