import React, { useState } from "react";
import "./CourseDescription.css";
import unix from '../../../assets/courses/unix.png';
import Nav from "../../utils/nav/Nav";
import profile from '../../../assets/placeholderimage.jpg';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
  {
    id: 1,
    image: profile,
    name: "Kumar",
    occupation: "Oracle DBA - Architect & Consultant"
  }
]

const UnixShellScriptingDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/df7b00c3-5cb1-488c-8cf5-6115cfdf3f7b/MP4/1-Shell-Introduction-1.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={unix} alt="kubernetes-thumbnail" />
						) : (
							<img src={unix} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Unix Shell Scripting </p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Unix Shell Scripting is often a must have skill for any IT Professional. In most  job profile, you will find it mandatory to have skills to Work in Unix or Linux Environment.
            In today's competitive environment, companies need professionals who are having good understand of Unix/Linux command and shell scripting.
            In Telecom, Big Data processing and Cloud Computing Domain, Unix commands and shell scripting are basic prerequisite to start working.
            - Here we will cover Unix commands which we can use in daily activities and also we have covered many interview question which are asked in interviews.

          </p>

          <p>Content</p>

          <ol>
            <li>
              Unix Basics
            </li>
            <li>
              Basic commands
            </li>
            <li>
              Check Log Files
            </li>
            <li>
              Basic files and folder operation
            </li>
            <li>
              File Search
            </li>
            <li>
              Content Search
            </li>
            <li>
              Memory related commands
            </li>
            <li>
              CPU Processing
            </li>
            <li>
              Merging of command outputs
            </li>
            <li>
              Practice questions
            </li>
          </ol>
        </div>
        <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img width={200} src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default UnixShellScriptingDesc;
