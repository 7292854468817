import React, { useState } from "react";
import "./CourseDescription.css";
import bigdata from '../../../assets/courses/bigdata.png';
import Nav from "../../utils/nav/Nav";
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const BigDataAdvancedHadoopDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/c71863ff-b372-4390-a953-dc336b8ffe00/MP4/1-B1-NoSQL-1.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">


        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={bigdata} alt="kubernetes-thumbnail" />
						) : (
							<img src={bigdata} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}          </div>

          <div>
            <div>
              <p className="course-title">Big Data Advanced Hadoop Ecosystem</p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Aspire to become a Big Data or Hadoop professional? But not sure which
            course to enroll? Then this course is for you ! This Course is a
            conitnuation of the Big Data Beginner Hadoop Ecosystem. In this course
            you will learn Big Data using the Hadoop Ecosystem. Why Hadoop? It is
            one of the most sought after skills in the IT industry. The average
            salary in the US is $112,000 per year, up to an average of $160,000 in
            San Fransisco (source: Indeed). You will learn how to use the most
            popular software in the Big Data industry at moment, using batch
            processing as well as realtime processing. This course will give you
            enough background to be able to talk about real problems and solutions
            with experts in the industry.
          </p>

          <p>Content</p>

          <ol>
            <li>BigData and NoSQL</li>
            <li>BigData and NoSQL Part 2</li>
            <li>Hbase</li>
            <li>Hbase Part 2</li>
            <li>Hbase Practicals</li>
            <li>Sqoop</li>
            <li>Sqoop Practicals</li>
            <li>Flume</li>
            <li>Flume Practical</li>
            <li>Oozie</li>
            <li>Oozie Practicals</li>
          </ol>
        </div>
        {/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
      </div>
    </>
  );
};

export default BigDataAdvancedHadoopDesc;
