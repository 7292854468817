import React, { useState } from 'react';
import './CourseDescription.css';
import github from '../../../assets/courses/github.png';
import Nav from '../../utils/nav/Nav';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const VersionControlDesc = () => {
  const [playVid, setPlayVid] = useState(false);
  const videoLink =
    'https://mediadrive.cloudplexo.com/assets/e5a7fce1-afa7-4ea8-857d-ee4263e2aaed/MP4/1-Introduction-to-Version-control-system.mp4';

  //play video on click of image
  const handlePlayImgVideo = () => {
    setPlayVid(true);
  };

  return (
    <React.Fragment>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
            {!playVid ? (
              <img
                src={github}
                alt="kubernetes-thumbnail"
              />
            ) : (
              <img
                src={github}
                alt="kubernetes-thumbnail"
                style={{ display: 'none' }}
              />
            )}
            <div
              className="play-icon-body"
              onClick={handlePlayImgVideo}
            >
              <BsFillPlayFill className="play-icon" />
            </div>
            {playVid ? (
              <ReactPlayer
                controls={true}
                className="react-player"
                url={videoLink}
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                      onContextMenu: (e) => e.preventDefault(),
                    },
                  },
                }}
              />
            ) : null}
          </div>

          <div>
            <div>
              <p className="course-title">
                Introduction To Version Control With Git/GitHub
              </p>
            </div>
            <div className="go-to-course-btn">
              <a href="https://app.ilearncloud.io/login">
                <button>Go to Course</button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background" />

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            Git is an essential tool for work in any code-related field, from
            data science to game development to machine learning. This course
            covers everything you need to know to start using Git and Github in
            the real-world today! We start off with Git Essentials. The goal of
            this unit is to give you all the essential Git tools you need for
            daily use. We start off talking about version control software, what
            it is, why it matters, and the history of Git.
          </p>

          <p>Content</p>

          <ol>
            <li>Introduction to Version control system</li>
            <li>Installing Git on Windows</li>
            <li>Git Commands</li>
            <li>
              Creating Account on GitHub and Pushing code to the remote
              repository
            </li>
          </ol>
        </div>
        {/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default VersionControlDesc;
