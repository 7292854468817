import React, { useState } from "react";
import "./CourseDescription.css";
import nosql from '../../../assets/courses/nosql.png';
import Nav from "../../utils/nav/Nav";
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const NoSQLAndMongoDBDesc = () => {
  const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
  'https://mediadrive.cloudplexo.com/assets/193d8f0f-c67e-4e2b-ba4d-df77619c878b/MP4/1-Introduction-to-NoSQL-Databases.mp4'

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
  };
  
  return (
    <>
      <Nav />
      <div className="course-description">
        <div className="course-description-intro">
          <div className="course-thumbnail">
          {!playVid ? (
							<img src={nosql} alt="kubernetes-thumbnail" />
						) : (
							<img src={nosql} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
          </div>

          <div>
            <div>
              <p className="course-title">Non Relational Databases - NoSQL And MongoDB</p>
            </div>
            <div className='go-to-course-btn'>
              <a href="https://app.ilearncloud.io/login">
                <button>
                  Go to Course
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="blue-background"></div>

        <div className="course-description-content">
          <h5>Course Description</h5>
          <hr />

          <p>
            This course will help you to master one of the most popular NoSQL databases MongoDB.
            In this course, we will start by learning basic concepts non-relational databases, core concepts of MongoDB, MongoDB installation process
            on different operating systems, creating databases, Collections, CRUD opertaions and Indexing.
            This course is designed to provide knowledge and hands on skills to become a successful MongoDB expert.
          </p>

          <p>Content</p>

          <ol>
            <li>
              Introduction to NoSQL Database
            </li>
            <li>
              Getting started installation configuration
            </li>
            <li>
              Basics of MongoDB
            </li>
            <li>
              MongoDB CRUD Operations
            </li>
            <li>
              Performance tuning with indexes in MongoDB
            </li>
            <li>
              ObjectIds in MongoDB
            </li>
            <li>
              Aggregation framework in mongoDB
            </li>
            <li>
              Data modelling in MongoDB
            </li>
            <li>
              Relationships in MongoDB
            </li>
            <li>
              User role management in MongoDB
            </li>
            <li>
              Clarification Section Course discussions
            </li>
            <li>
              Reguler expressions in MongoDB
            </li>
            <li>
              MapReduce in MongoDB
            </li>
            <li>
              MongoDB samples
            </li>
          </ol>
        </div>
        {/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
      </div>
    </>
  );
};

export default NoSQLAndMongoDBDesc;
