import React, { useState } from 'react';
import './CourseDescription.css';
import bigdatabeginner from '../../../assets/courses/bigdata-beginner.png';
import Nav from '../../utils/nav/Nav';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const BigDataBeginnerHadoopDesc = () => {
	const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
		'https://mediadrive.cloudplexo.com/assets/f21f9d5c-07db-4413-b900-3bbc1eed402d/MP4/1-A1-Hadoop-intro-1.mp4';

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
	};

	return (
		<React.Fragment>
			<Nav />
			<div className="course-description">
				<div className="course-description-intro">
					<div className="course-thumbnail">
						{!playVid ? (
							<img src={bigdatabeginner} alt="kubernetes-thumbnail" />
						) : (
							<img src={bigdatabeginner} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
					</div>

					<div>
						<div>
							<p className="course-title">Big Data Beginner Hadoop Ecosystem</p>
						</div>
						<div className="go-to-course-btn">
							<a href="https://app.ilearncloud.io/login">
								<button>Go to Course</button>
							</a>
						</div>
					</div>
				</div>

				<div className="blue-background" />

				<div className="course-description-content">
					<h5>Course Description</h5>
					<hr />

					<p>
						Do you find Big Data and Hadoop confusing? Aspire to become a Big Data or Hadoop professional?
						But not sure which course to enroll? Then this course is for you ! In this course you will learn
						Big Data using the Hadoop Ecosystem. Why Hadoop? It is one of the most sought after skills in
						the IT industry. The average salary in the US is $112,000 per year, up to an average of $160,000
						in San Fransisco (source: Indeed). You will learn how to use the most popular software in the
						Big Data industry at moment, using batch processing as well as realtime processing. This course
						will give you enough background to be able to talk about real problems and solutions with
						experts in the industry.
					</p>

					<p>Content</p>

					<ol>
						<li>Hadoop introduction</li>
						<li>Hadoop introduction Part 2</li>
						<li>Python Practicals</li>
						<li>Linux Practicals</li>
						<li>HDFS</li>
						<li>HDFS Practicals</li>
						<li>MapReduce</li>
						<li>MapReduce Practicals</li>
						<li>Pig</li>
						<li>Pig Practicals</li>
						<li>Hive</li>
						<li>Hive Practicals</li>
					</ol>
				</div>
				{/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
			</div>
		</React.Fragment>
	);
};

export default BigDataBeginnerHadoopDesc;
