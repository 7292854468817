import React, { useState } from 'react';
import './CourseDescription.css';
import datascience from '../../../assets/courses/datascience.png';
import Nav from '../../utils/nav/Nav';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const DataScienceAndMLWithRDesc = () => {
	const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
		'https://mediadrive.cloudplexo.com/assets/d615fae6-25db-43e8-a9ac-12ce3668d8c9/MP4/1-Introduction.mp4';

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
	};

	return (
		<React.Fragment>
			<Nav />
			<div className="course-description">
				<div className="course-description-intro">
					<div className="course-thumbnail">
						{!playVid ? (
							<img src={datascience} alt="kubernetes-thumbnail" />
						) : (
							<img src={datascience} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}{' '}
					</div>

					<div>
						<div>
							<p className="course-title">Complete Data Science And Machine Learning With R</p>
						</div>
						<div className="go-to-course-btn">
							<a href="https://app.ilearncloud.io/login">
								<button>Go to Course</button>
							</a>
						</div>
					</div>
				</div>

				<div className="blue-background" />

				<div className="course-description-content">
					<h5>Course Description</h5>
					<hr />

					<p>
						This course has been designed by professional Data Scientists so that we can share their
						knowledge and help you learn complex theory, algorithms, and coding libraries in a simple way.
						The course would walk you step-by-step into the World of Machine Learning. With every tutorial,
						you will develop new skills and improve your understanding of this challenging yet lucrative
						sub-field of Data Science. This course is fun and exciting, but at the same time, we dive deep
						into Machine Learning. Data Scientist has been ranked the number one job on Glassdoor and the
						average salary of a data scientist is over $120,000 in the United States according to Indeed!
						Data Science is a rewarding career that allows you to solve some of the world's most interesting
						problems!
					</p>

					<p>Content</p>

					<ol>
						<li>Course introduction</li>
						<li>Hands on R Programming</li>
						<li>Machine learning Fundamentals</li>
						<li>Data visualisation with R</li>
						<li>Applied statistics for ML</li>
						<li>ML fundamentals</li>
						<li>ANOVA With R</li>
						<li>Logistic Regression with R</li>
						<li>Dimension reduction technique with principal component analysis</li>
						<li>Clustering With K Means</li>
						<li>Tree based models CART technique and Random-Forest</li>
						<li>KNN K Nearest Model</li>
						<li>Naive Bayes</li>
						<li>Neural Networks With R</li>
					</ol>
				</div>
				{/* <div className="container">
          <div className="row instructor-profile">
            {CourseInstructor.map((instructor) => (
              <div className="col-md-4">
                <div>
                  <img src={instructor.image} className="img-fluid" alt="instructor" />
                  <h4>{instructor.name}</h4>
                  <p>{instructor.occupation}</p>
                </div>
              </div>
            ))}

          </div>
        </div> */}
			</div>
		</React.Fragment>
	);
};

export default DataScienceAndMLWithRDesc;
