import React, { useState } from 'react';
import './CourseDescription.css';
import computing from '../../../assets/courses/computing.png';
import Nav from '../../utils/nav/Nav';
import ewere from '../../../assets/Ewere.png';
import simi from '../../../assets/Simisola.png';
import kehinde from '../../../assets/Kehinde.png';
import ReactPlayer from 'react-player';
import { BsFillPlayFill } from 'react-icons/bs';

const CourseInstructor = [
	{
		id: 1,
		image: ewere,
		name: 'Ewere Diagboya',
		occupation: 'Cloud Engineer'
	},
	{
		id: 2,
		image: simi,
		name: 'Simisola Saheyi',
		occupation: 'IT Infrastructure Lead'
	},
	{
		id: 3,
		image: kehinde,
		name: 'Kehinde Olashore',
		occupation: 'CTO (Datacrest Technologies)'
	}
];
const EdgeComputingDesc = () => {
	const [ playVid, setPlayVid ] = useState(false);
	const videoLink =
		'https://mediadrive.cloudplexo.com/assets/1bd46519-7692-414a-b17f-c209d228d7a2/MP4/1-Introduction-to-cloud-and-edge-computing.mp4';

	//play video on click of image
	const handlePlayImgVideo = () => {
		setPlayVid(true);
	};

	return (
		<React.Fragment>
			<Nav />
			<div className="course-description">
				<div className="course-description-intro">
					<div className="course-thumbnail">
						{!playVid ? (
							<img src={computing} alt="kubernetes-thumbnail" />
						) : (
							<img src={computing} alt="kubernetes-thumbnail" style={{ display: 'none' }} />
						)}
						<div className="play-icon-body" onClick={handlePlayImgVideo}>
							<BsFillPlayFill className="play-icon" />
						</div>
						{playVid ? (
							<ReactPlayer
								controls={true}
								className="react-player"
								url={videoLink}
								width="100%"
								height="100%"
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											onContextMenu: (e) => e.preventDefault()
										}
									}
								}}
							/>
						) : null}
					</div>

					<div>
						<div>
							<p className="course-title">
								Edge Computing Driving: The Next Evolution of Internet Technology
							</p>
						</div>
						<div className="go-to-course-btn">
							<a href="https://app.ilearncloud.io/login">
								<button>Go to Course</button>
							</a>
						</div>
					</div>
				</div>

				<div className="blue-background" />

				<div className="course-description-content">
					<h5>Course Description</h5>
					<hr />

					<p>
						This conference or lecture will help you overcome critical challenges - reducing latency,
						building quality infrastructure, and decreasing costs - with real-time data processing. Learn
						from key players in the Cloud ecosystem and build partnerships that will drive edge computing to
						the next level.
					</p>

					<p>Content</p>

					<ol>
						<li>Introduction to Cloud Computing and Edge Computing(Xpress Payments Limited)</li>
						<li>
							Business Problems that can be solved using Edge Computing (Co-Founder and CTO Datacrest)
						</li>
						<li>
							Edge and its use cases (AWS Community Hero And Head of Infrastructure and Data Protection
							Indicina)
						</li>
					</ol>
				</div>
				<div className="container">
					<div className="row instructor-profile">
						{CourseInstructor.map((instructor) => (
							<div className="col-md-4">
								<div>
									<img src={instructor.image} className="img-fluid" alt="instructor" />
									<h4>{instructor.name}</h4>
									<p>{instructor.occupation}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default EdgeComputingDesc;
